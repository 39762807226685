export const mockInvestorEntities = {
  businessesLimitReached: false,
  businessLimit: 2,
  entities: [{
    id: '1',
    name: 'John Doe New',
    type: 'INDIVIDUAL',
    accounts: [{
      id: '1',
      managementStrategy: 'SELF'
    }],
    status: {
      identity: {
        address: {
          addressLine1: '215 West 95 St',
          addressLine2: 'Apt 14F',
          city: 'New York',
          state: 'NY',
          postalCode: '10025',
          country: 'US'
        },
        status: 'CONFIRMED',
        provider: 'PLAID'
      },
      accreditation: {
        status: 'CONFIRMED',
        provider: 'YS'
      },
      bankAccounts: {
        status: 'CONFIRMED',
        provider: 'PLAID'
      }
    }
  }, {
    id: '842759',
    name: 'Equity Trust Company Custodian FBO',
    type: 'BUSINESS',
    accounts: [{
      id: '842759',
      managementStrategy: 'SELF'
    }],
    status: {
      identity: {
        address: null,
        status: 'MISSING',
        provider: null
      },
      accreditation: {
        status: 'CONFIRMED',
        provider: 'YS'
      },
      bankAccounts: {
        status: 'CONFIRMED',
        provider: 'ETC'
      }
    }
  }, {
    id: '871943',
    name: 'John Doe',
    type: 'INDIVIDUAL',
    accounts: [{
      id: '871761',
      managementStrategy: 'SELF'
    }],
    status: {
      identity: {
        address: null,
        status: 'MISSING',
        provider: 'PLAID'
      },
      accreditation: {
        status: 'MISSING',
        provider: 'YS'
      },
      bankAccounts: {
        status: 'MISSING',
        provider: 'PLAID'
      }
    }
  }],
  individualLimit: 2,
  individualsLimitReached: false,
  iraLimit: 5,
  iraLimitReached: false,
  tags: [],
  trustLimit: 2,
  trustsLimitReached: false,
  userId: '1'
};